var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"\n    w-100\n    d-lg-flex\n    align-items-center\n    justify-content-center\n    px-5\n"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Forgot password V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Reset Password 🔒 ")]),(!_vm.isDone)?_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please enter your new password. ")]):_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Your password has been changed. Please return to the login page and login with your new password. ")]),_c('validation-observer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDone),expression:"!isDone"}],ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{ref:"password",attrs:{"name":"password","rules":"required|min:8|max:64|not_contains:@email|strong_password"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"New Password","label-for":"reset-password"}},[_c('b-form-input',{attrs:{"id":"reset-password","type":"password","state":_vm.getValidationState(
                        validationContext
                      ),"name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                        validationContext
                      )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{ref:"password_confirmation",attrs:{"rules":"required|confirmed_password:password","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"reset-password-confirmation"}},[_c('b-form-input',{attrs:{"id":"reset-password-confirmation","type":"password","state":_vm.getValidationState(
                        validationContext
                      ),"name":"password_confirmation"},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                        validationContext
                      )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":pristine || invalid || _vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Save ")],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":_vm.returnToLoginRoute}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Back to login ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }