<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="
    w-100
    d-lg-flex
    align-items-center
    justify-content-center
    px-5
"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Reset Password 🔒
          </b-card-title>

          <b-card-text
            v-if="!isDone"
            class="mb-2"
          >
            Please enter your new password.
          </b-card-text>
          <b-card-text
            v-else
            class="mb-2"
          >
            Your password has been changed. Please return to the login page and login with your new password.
          </b-card-text>

          <!-- form -->
          <validation-observer
            v-show="!isDone"
            ref="validationObserver"
            v-slot="{ pristine, invalid, handleSubmit }"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <validation-provider
                v-slot="validationContext"
                ref="password"
                name="password"
                rules="required|min:8|max:64|not_contains:@email|strong_password"
              >
                <b-form-group
                  label="New Password"
                  label-for="reset-password"
                >
                  <b-form-input
                    id="reset-password"
                    v-model="password"
                    type="password"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                    name="password"
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="validationContext"
                ref="password_confirmation"
                rules="required|confirmed_password:password"
                name="Confirm Password"
              >
                <b-form-group
                  label="Confirm Password"
                  label-for="reset-password-confirmation"
                >
                  <b-form-input
                    id="reset-password-confirmation"
                    v-model="password_confirmation"
                    type="password"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                    name="password_confirmation"
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="pristine || invalid || isLoading"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Save
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="returnToLoginRoute">
              <feather-icon icon="ChevronLeftIcon" /> Back to
              login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import {
  getValidationState,
} from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BSpinner
} from "bootstrap-vue";
//eslint-disable-next-line
import { required, min, max, strongPassword, notContains } from "@validations";
import store from "@/store/index";
import authService from "@/services/authService";
import { makeErrorToast } from "@/libs/utils";
import { mapGetters } from 'vuex';
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BSpinner
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      sideImg: require("@/assets/images/logo/logo-h.png"),
      isDone: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("app", ["isParticipantPortal"]),
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    returnToLoginRoute () {
      return !this.isParticipantPortal 
        ? { name: 'auth-login' } 
        : { name: 'participant-login', params: { programPath: this.$route.params.programPath }};
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        await authService.resetPassword({
          password: this.password, 
          password_confirmation: this.password_confirmation,
          token: this.$route.params.token
        });
        this.isDone = true;
        this.isLoading = false;
        return true;
      } catch (e) {
        this.isLoading = false;
        //server may respond with validation errors
      if (e?.response) {
        const { status, data } = e.response;
        if (data.errors) {
          if (status === 422) {
              this.$refs.validationObserver.setErrors(data.errors);
            } else {
              this.$toast(makeErrorToast(e));
              this.$log.error(e);
            }
            return false;
          }
          else if (status === 400) {
            this.$toast(makeErrorToast("Link has expired. Please request your program manager to resend access. "));
          }
        }
      }
    },
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vue/pages/page-auth.scss";
</style>
